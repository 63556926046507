<script setup lang="ts">
import { computed } from "vue";
import { ErrorIcon } from "../";
import { useI18n } from "vue-i18n";
import DOMPurify from "dompurify";
import {
  RUNA_CUSTOMER_SUPPORT_URL,
  DEFAULT_ERROR_KEY,
  TIMEOUT_ERROR_CODE,
} from "../../constant";
const { t } = useI18n();

const props = defineProps<{
  errorCode: string | undefined;
  isAsset?: boolean;
  customerSupportUrl: string | undefined;
}>();

const errorMapper = computed(
  (): {
    [key: string]: { title: string; message: string };
  } => {
    return {
      RE001: {
        title: t("error.link_is_invalid_title"),
        message: t("error.contact_the_business"),
      },
      RE002: {
        title: t("error.issue_with_your_payout_title"),
        message: t("error.contact_customer_support", {
          msg: getFormattedCustomerSupportArg(RUNA_CUSTOMER_SUPPORT_URL),
        }),
      },
      RE003: {
        title: t("error.link_is_invalid_title"),
        message: t("error.contact_the_business"),
      },
      RE004: {
        title: t("error.link_is_invalid_title"),
        message: t("error.contact_the_business"),
      },
      RE005: {
        title: t("error.payout_has_been_cancelled_title"),
        message: t("error.contact_the_business"),
      },
      RE006: {
        title: t("error.temporary_issue_with_payout_title"),
        message: t("error.try_again_shortly"),
      },
      RE007: {
        title: t("error.temporary_issue_with_payout_title"),
        message: t("error.try_again_shortly"),
      },
      RE008: {
        title: t("error.temporary_issue_with_payout_title"),
        message: `${t("error.processing_error")}<br>${t(
          "error.wait_five_minutes"
        )}`,
      },
      RE009: {
        title: t("error.temporary_issue_with_payout_title"),
        message: t("error.processing_error"),
      },
      RE010: {
        title: t("error.temporary_issue_with_payout_title"),
        message: t("error.try_again_shortly"),
      },
      RE011: {
        title: t("error.link_is_invalid_title"),
        message: t("error.contact_the_business"),
      },
      RE012: {
        title: t("error.temporary_issue_with_payout_title"),
        message: t("error.contact_customer_support", {
          msg: getFormattedCustomerSupportArg(RUNA_CUSTOMER_SUPPORT_URL),
        }),
      },
      RE014: {
        title: t("error.temporary_issue_with_payout_title"),
        message: t("error.contact_customer_support", {
          msg: getFormattedCustomerSupportArg(RUNA_CUSTOMER_SUPPORT_URL),
        }),
      },
      RE015: {
        title: t("error.temporary_issue_with_payout_title"),
        message: t("error.try_again_shortly"),
      },
      RE999: {
        title: t("error.temporary_issue_with_payout_title"),
        message: t("error.contact_customer_support", {
          msg: getFormattedCustomerSupportArg(RUNA_CUSTOMER_SUPPORT_URL),
        }),
      },
      [`${DEFAULT_ERROR_KEY}`]: {
        title: t("error.title"),
        message: t("error.general", {
          msg: getFormattedCustomerSupportArg(RUNA_CUSTOMER_SUPPORT_URL),
        }),
      },
    };
  }
);

const errorMapperKey = computed(() => {
  const errorMapperKeys = Object.keys(errorMapper.value);
  return props.errorCode && errorMapperKeys.includes(props.errorCode)
    ? props.errorCode
    : DEFAULT_ERROR_KEY;
});

const errorTitle = computed(() => {
  return errorMapper.value[errorMapperKey.value]?.title || t("error.title");
});

const formattedErrorMessage = computed(() => {
  const error = errorMapper.value[errorMapperKey.value];
  const message = error.message || t("error.general");
  return `${message} ${
    errorMapperKey.value != DEFAULT_ERROR_KEY ? `(${errorMapperKey.value})` : ""
  }`;
});

const formattedFeTimeoutMessage = computed(() => {
  return t("error.payouts_timeout_message", {
    msg: getFormattedCustomerSupportArg(props.customerSupportUrl),
  });
});

const getFormattedCustomerSupportArg = (
  customerSupportUrl: string | undefined
): string => {
  const msgArg = t("error.customer_support");
  if (customerSupportUrl) {
    return `<a href='${customerSupportUrl}' class="text-blue-500 underline">${msgArg}</a>`;
  }
  return msgArg;
};
</script>

<template>
  <div
    v-if="errorCode === TIMEOUT_ERROR_CODE"
    class="mt-12 flex flex-col items-center gap-4"
  >
    <div v-if="!isAsset" style="min-height: 86.5px">
      <!-- This is to push the SPL error message down as much as the MPL asset error message is pushed by the Back button -->
    </div>
    <p class="font-heading text-xl font-extrabold">
      {{ $t("message.balance_ready_soon") }}
    </p>
    <p
      class="max-w-[575px] text-center text-base"
      v-html="DOMPurify.sanitize(formattedFeTimeoutMessage)"
    ></p>
  </div>
  <div v-else class="h-full">
    <div class="m-auto max-w-3xl p-4 md:p-10">
      <div class="">
        <div class="rounded-2xl bg-grey-300 px-3 py-4 md:p-6">
          <div class="mb-4 flex items-center">
            <div class="m-auto drop-shadow-xl">
              <ErrorIcon />
            </div>
          </div>

          <div class="mb-2 text-center text-lg font-semibold md:text-2xl">
            {{ errorTitle }}
          </div>

          <div
            class="text-center text-sm md:text-base"
            v-html="DOMPurify.sanitize(formattedErrorMessage)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
